import styled from 'styled-components'
import { Text, Box } from 'grommet'
import is from 'styled-is'
import Life from '../../../images/life.svg'
import { colors, responsive } from '../../../theme';

export const BoxWrapper = styled(Box)`
  background-image: url(${Life});
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 50%;
`;

export const InfoWrapper = styled(Box)`
  padding-right: 18%;
  
  ${responsive.smAndLess} {
    padding-right: 0;
  }
`;

export const Title = styled(Text)`
  font-size: 5rem;
  color: ${colors.black};
  font-weight: bold;
  line-height: 1.5;
  font-family: 'Monda', 'Cinzel', 'Ubuntu', sans-serif;
  
  ${responsive.xsAndLess} {
    font-size: 2.5rem;
  }
  
  ${responsive.md} {
    font-size: 4rem;
  }
  
  ${responsive.sm} {
    font-size: 2.7rem;
  }
  
  ${is('float')`
    position: sticky;
    width: 700px;
    z-index: 3;
    background-color: white;
    ${responsive.xsAndLess} {
      width: auto;
    }
      
    ${responsive.sm} {
      width: 500px;
    }
  `};
`;

export const BigText = styled(Text)`
  font-size: 14rem;
  color: ${colors.black};
  font-weight: bold;
  line-height: 1.5;
  font-family: 'Monda', 'Cinzel', 'Ubuntu', sans-serif;
  
  ${responsive.xsAndLess} {
    font-size: 7rem;
  }
  
  ${responsive.md} {
    font-size: 12rem;
  }
  
  ${responsive.sm} {
    font-size: 9rem;
  }
  
  ${is('float')`
    position: sticky;
    width: 700px;
    z-index: 3;
    background-color: white;
    ${responsive.xsAndLess} {
      width: auto;
    }
      
    ${responsive.sm} {
      width: 500px;
    }
  `};
`;

export const SubTitle = styled(Text)`
  font-size: 1.1rem;
  color: ${colors.black};
  letter-spacing: 1.2px;
  word-break: keep-all;
  font-family: 'Roboto Slab', 'Cinzel', serif;
  
  ${responsive.xsAndLess} {
    font-size: 1rem;
  }
`;

export const Info = styled(Text)`
  font-size: .8rem;
  color: ${colors.black};
  letter-spacing: 1.2px;
  word-break: keep-all;
  font-family: 'Roboto Slab', 'Cinzel', serif;
  
  ${responsive.xsAndLess} {
    font-size: .6rem;
  }
`;
