import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NotFoundFragment from "../components/fragments/not-found-fragment"

const NotFoundPage = (props) => (
  <Layout {...props}>
    <SEO title="404: Not found" />
    <NotFoundFragment />
  </Layout>
)

export default NotFoundPage
