import React from "react"
import { Box as GBox, Button } from "grommet"
import { Column, Box } from "gestalt"
import { SubTitle, BigText, Info, BoxWrapper } from "./styles"
import Image from "./image"

import FragmentWrapper from "../fragment-wrapper"

const NotFoundFragment = () => {
  return (
    <FragmentWrapper
      rightContent={() => <div />}
      leftContent={() => <div />}
      bottomContent={() => <div />}
    >
      <BoxWrapper fill pad={{ horizontal: 'large' }}>
        <Box flex paddingY={10} wrap>
          <Column lgSpan={6} md={6} mdSpan={6} span={12}>
            <GBox fill justify="center" align="center">
              <Box paddingY={1}>
                <BigText>404</BigText>
              </Box>
              <SubTitle>PAGE NOT FOUND</SubTitle>
              <Box paddingY={1}>
                <Info>The page you are looking for does not exist</Info>
              </Box>
              <Box paddingY={3}>
                <Button onClick={() => window.history.go(-1)} fill label="GO BACK" primary />
              </Box>
            </GBox>
          </Column>
          <Column lgSpan={6} md={6} mdSpan={6} span={12}>
            <GBox justify="center" align="stretch">
              <Image />
            </GBox>
          </Column>
        </Box>
      </BoxWrapper>
    </FragmentWrapper>
  )
}
export default NotFoundFragment
